import mapboxgl from "mapbox-gl/dist/mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

export default class Dealers {
  constructor() {
    this.map = ""
    this.allMarkers = []
    this.markers = []
    this.managers = []
    this.results = $("section.results")

    mapboxgl.accessToken =
      "pk.eyJ1IjoiY3Vlc3dlYnNpdGUyMDE4IiwiYSI6ImNqbWplNHBoaDBlbW4zcnBreHdveWpyY2MifQ.HGcpnSQZntR10srEBuXAew"

    this.initMap()

    this.regionToggle = $("#region-toggle")
    this.regionList = $("#region-list")
    this.regionLinks = $("#region-list a")

    this.defaultSelectedText = this.regionToggle.find(".selected-region").text()

    this.regionToggle.on("click", (e) => {
      this.regionToggle.toggleClass("open")

      if (this.regionToggle.hasClass("open")) {
        this.regionList.stop().slideDown(400)
      } else {
        this.regionList.stop().slideUp(400)
      }
    })

    this.regionLinks.on("click", (e) => {
      const target = $(e.currentTarget || e.target)
      this.filterByRegion(target)
    })

    $(document).on("click", ".dealer__header", (e) => {
      const target = $(e.currentTarget || e.target)
      this.openDealer(target)
    })

    $("#dealer-search").on("submit", (e) => {
      e.preventDefault()
      this.filterByLocation()
    })
  }

  initMap() {
    if ($("#map").length > 0) {
      this.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/cueswebsite2018/cjmjg3tst3c6i2rrz5j323ggu",
        attributionControl: false,
        center: [-37.881208, 39.833],
        zoom: 2,
        minZoom: 1,
        maxZoom: 16,
        scrollZoom: false,
      })

      this.map.addControl(new mapboxgl.NavigationControl(), "top-left")

      $.ajax({
        url: "/dealers.json",
        method: "get",
        success: (data) => {
          data.forEach((markerObject) => {
            if (markerObject.latitude) {
              let markerElement = document.createElement("div")
              markerElement.className = "marker"
              markerElement.dataset.index = markerObject.id

              markerObject.marker = new mapboxgl.Marker(markerElement, {
                offset: {
                  x: 0,
                  y: -17.5,
                },
              })
                .setLngLat([markerObject.longitude, markerObject.latitude])
                .addTo(this.map)

              this.allMarkers.push(markerObject)
              this.markers.push(markerObject)

              markerElement.addEventListener("click", () => {
                $(".marker").removeClass("active")
                $(markerElement).addClass("active")

                this.map.flyTo({
                  center: [markerObject.longitude, markerObject.latitude],
                  speed: 1,
                })

                this.openDealerOnMarkerClick(markerObject.id)
              })
            }
          })

          this.displayDealers()
        },
        error: () => {
          console.error("Error loading map data")
        },
      })
    }
  }

  filterByRegion(target) {
    const region = target.text()

    this.markers.forEach((markerObject) => {
      markerObject.marker.remove()
    })

    if (region === "View All") {
      this.regionToggle
        .removeClass("open")
        .find(".selected-region")
        .text(this.defaultSelectedText)

      this.regionList.stop().slideUp(400, () => {
        this.regionList.find(".view-all").css("display", "none")
      })

      this.markers = this.allMarkers

      this.markers.forEach((markerObject) => {
        markerObject.marker.addTo(this.map)
      })

      this.map.flyTo({
        center: [-37.881208, 39.833],
        zoom: 2,
      })

      this.displayDealers()
    } else {
      this.regionToggle
        .removeClass("open")
        .find(".selected-region")
        .text(region)

      this.regionList.stop().slideUp(400, () => {
        this.regionList.find(".view-all").css("display", "block")
      })

      this.markers = this.allMarkers.filter(
        (markerObject) => markerObject.region === region
      )

      let bounds = new mapboxgl.LngLatBounds()

      if (this.markers.length) {
        this.markers.forEach((markerObject) => {
          markerObject.marker.addTo(this.map)
          bounds.extend(markerObject.marker.getLngLat())
        })

        this.map.fitBounds(bounds, {
          padding: {
            top: 89,
            bottom: 54,
            left: 67,
            right: 67,
          },
          maxZoom: 13,
        })
      }

      this.displayDealers(region)
    }
  }

  filterByLocation() {
    const value = $('#dealer-search input[type="text"]').val()

    if (value) {
      $.ajax({
        url: "/dealers.js",
        method: "get",
        data: {
          location_search: value,
        },
        success: () => {
          this.markers.forEach((markerObject) => {
            markerObject.marker.remove()
          })

          this.regionToggle
            .removeClass("open")
            .find(".selected-region")
            .text(this.defaultSelectedText)

          this.regionList.stop().slideUp(400, () => {
            this.regionList.find(".view-all").css("display", "none")
          })

          let markerIds = []

          $(".dealer").each((_, dealer) => {
            const markers = `${$(dealer).data("markers")}`.split(",")
            markerIds = [...markerIds, ...markers]
          })

          this.markers = this.allMarkers.filter(
            (markerObject) => markerIds.indexOf(`${markerObject.id}`) > -1
          )

          if (this.markers.length) {
            let bounds = new mapboxgl.LngLatBounds()

            this.markers.forEach((markerObject) => {
              markerObject.marker.addTo(this.map)
              bounds.extend(markerObject.marker.getLngLat())
            })

            this.map.fitBounds(bounds, {
              padding: {
                top: 89,
                bottom: 54,
                left: 67,
                right: 67,
              },
              maxZoom: 13,
            })
          }
        },
      })
    } else {
      this.markers.forEach((markerObject) => {
        markerObject.marker.remove()
      })

      this.markers = this.allMarkers

      this.markers.forEach((markerObject) => {
        markerObject.marker.addTo(this.map)
      })

      this.map.flyTo({
        center: [-37.881208, 39.833],
        zoom: 2,
      })

      this.displayDealers()
    }
  }

  displayDealers(region) {
    $(".marker").removeClass("active")

    if (region) {
      const dealerIds = this.markers.map((marker) => marker.id)

      $.ajax({
        url: "/dealers.js",
        method: "get",
        data: {
          dealer_ids: dealerIds,
        },
      })
    } else {
      $.ajax({
        url: "/dealers.js",
        method: "get",
      })
    }
  }

  openDealer(target) {
    if (!target.hasClass("dealer__header--open")) {
      $(".dealer__header").removeClass("dealer__header--open")
      $(".dealer__content").stop().slideUp(400)

      setTimeout(() => {
        $("html, body").animate({ scrollTop: target.offset().top }, 800)
      }, 400)

      target.addClass("dealer__header--open")
      target.next().css({ display: "flex" }).hide().stop().slideDown(400)

      $(".marker").removeClass("active")
      const markers = `${target.parent().data("markers")}`.split(",")
      let foundMarkers = []

      markers.forEach((markerIndex) => {
        $(`.marker[data-index='${markerIndex}']`).addClass("active")
        const markerData = this.allMarkers.find(
          (marker) => marker.id == markerIndex
        )
        foundMarkers.push(markerData)
      })

      let bounds = new mapboxgl.LngLatBounds()

      foundMarkers.forEach((markerObject) => {
        bounds.extend(markerObject.marker.getLngLat())
      })

      const center = bounds.getCenter()
      this.map.flyTo({
        center: center,
        speed: 1,
      })
    } else {
      $(".marker").removeClass("active")
      target.removeClass("dealer__header--open")
      target.next().stop().slideUp(400)
    }
  }

  openDealerOnMarkerClick(markerIndex) {
    $(".dealer").each((index, dealer) => {
      const $dealer = $(dealer)
      const markerArray = `${$dealer.data("markers")}`.split(",")

      if (markerArray.indexOf(`${markerIndex}`) > -1) {
        const header = $dealer.find(".dealer__header")

        this.openDealer(header)
      }
    })
  }
}
