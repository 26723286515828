import "jquery-hoverintent"

export default class Header {
  constructor() {
    this.headerSearch = $(".header__search")

    this.focusButton = $("#search-focus")
    this.submitButton = $("#search-submit")
    this.searchField = $("#search-field")

    this.mainFocusButton = $("#main-search-focus")
    this.mainSubmitButton = $("#main-search-submit")
    this.mainSearchField = $("#main-search-field")

    this.moduleFocusButton = $("#module-search-focus")
    this.moduleSubmitButton = $("#module-search-submit")
    this.moduleSearchField = $("#module-search-field")

    this.mainNav = $(".primary-nav")
    this.mainNavLinks = this.mainNav.find("> li > a")
    this.subNav = $(".sub-nav")

    this.menuToggle = $(".menu-toggle")
    this.menu = $(".header__nav")

    this.translateButton = $(
      ".header__translate__button:not(.header__region__button)"
    )
    this.translateMenu = $(
      ".header__translate__menu:not(.header__region__menu)"
    )
    this.regionButton = $(".header__region__button")
    this.regionMenu = $(".header__region__menu")

    this.searchEventHandler(
      this.focusButton,
      this.submitButton,
      this.searchField,
      true
    )

    this.searchEventHandler(
      this.mainFocusButton,
      this.mainSubmitButton,
      this.mainSearchField
    )

    this.searchEventHandler(
      this.moduleFocusButton,
      this.moduleSubmitButton,
      this.moduleSearchField
    )

    this.translateButton.on("click", (e) => {
      this.translateMenu.toggleClass("header__translate__menu--visible")
      this.translateButton.toggleClass("header__translate__button--open")
    })

    this.regionButton.on("click", (e) => {
      this.regionMenu.toggleClass("header__translate__menu--visible")
      this.regionButton.toggleClass("header__translate__button--open")
    })

    this.mainNavLinks.on("focus", (e) => {
      this.subNav.removeClass("sub-nav--show")
      const target = $(e.currentTarget || e.target)
      this.showSubNav(target)
    })

    this.subNav.find("> li > a").on("focus", (e) => {
      this.subNav.removeClass("sub-nav--show")

      const target = $(e.currentTarget || e.target)
      target.parents(".sub-nav").addClass("sub-nav--show")
    })

    this.mainNavLinks.hoverIntent({
      over: (e) => {
        this.subNav.removeClass("sub-nav--show")
        const target = $(e.currentTarget || e.target)
        this.showSubNav(target)
      },
      sensitivity: 5,
      interval: 50,
    })

    const outsideOfNav = $(".header__logo, .header__search, main")
    outsideOfNav.hoverIntent({
      over: () => {
        this.subNav.removeClass("sub-nav--show")
      },
      sensitivity: 5,
      interval: 50,
    })

    this.menuToggle.on("click", (e) => {
      if (this.menuToggle.hasClass("menu-toggle--open")) {
        this.menuToggle.removeClass("menu-toggle--open")
        this.menuToggle.attr("aria-label", "Open Menu")
        this.menu.fadeOut(400)
        $("main").show()
      } else {
        this.menuToggle.addClass("menu-toggle--open")
        this.menuToggle.attr("aria-label", "Close Menu")
        this.menu.fadeIn(400)
        $("main").hide()
      }
    })

    $(window).resize(() => {
      if ($(window).width() >= 1024) {
        $("main").show()
      } else if (
        $(window).width() < 1024 &&
        this.menuToggle.hasClass("menu-toggle--open")
      ) {
        $("main").hide()
      }
    })
  }

  showSubNav(target) {
    const targetSubNav = target
      .parent()
      .find(".sub-nav")
      .addClass("sub-nav--show")
  }

  hideSubNav(target) {
    const targetSubNav = target
      .parent()
      .find(".sub-nav")
      .removeClass("sub-nav--show")
  }

  searchEventHandler(focusButton, submitButton, searchField, focusedClass) {
    if (searchField.val() !== "") {
      focusButton.hide()
      submitButton.show()
    }

    focusButton.on("click", (e) => {
      focusButton.hide()
      submitButton.show()
      searchField.focus()

      if (focusedClass) {
        searchField.addClass("input-focused")
      }
    })

    searchField.on("blur", () => {
      if (searchField.val() === "") {
        focusButton.show()
        submitButton.hide()

        if (focusedClass) {
          searchField.removeClass("input-focused")
        }
      }
    })
  }
}
