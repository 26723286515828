export default class Forms {
  constructor() {
    this.form = $(".form-block__form form")

    window.initForms = () => {
      this.form = $(".form-block__form form")
      this.form
        .find(
          'input:not([type="checkbox"]):not([type="radio"]):not([type="hidden"]), textarea, select'
        )
        .each((i, input) => {
          const $input = $(input)
          this.floatLabel($input)
        })

      this.initCustomFileUpload()
    }

    window.initForms()

    this.form
      .find(
        'input:not([type="checkbox"]):not([type="radio"]):not([type="hidden"]), textarea, select'
      )
      .on("change", (e) => {
        const $input = $(e.currentTarget || e.target)
        this.floatLabel($input)
      })

    const placeholder = $(".products #module-search-field").attr("placeholder")

    $(window).on("load resize", () => {
      const productSearch = $(".products #module-search-field")

      if ($(window).width() >= 500) {
        productSearch.attr("placeholder", placeholder)
      } else {
        productSearch.attr("placeholder", "Search equipment")
      }
    })
  }

  floatLabel(input) {
    let placeholder = input.attr("placeholder")

    const tag = input.prop("tagName")
    if (tag.toLowerCase() === "select") {
      placeholder = input.find('option[value=""]').text()
    }

    if (input.val() || placeholder) {
      input.parents(".form-group").addClass("focused")
    } else {
      input.parents(".form-group").removeClass("focused")
    }
  }

  initCustomFileUpload() {
    $('.image-upload input[type="file"]').each((index, input) => {
      const $file = $(input).addClass("custom-file-upload-hidden"),
        $wrap = $('<div class="file-upload-wrapper">'),
        $selected = $('<div class="selected-files">')

      const buttonText = $file.data("placeholder")
        ? $file.data("placeholder")
        : "Upload Images"
      const $button = $(
        `<button type="button" class="file-upload-button">${buttonText}</button>`
      )

      // Hide by shifting to the left so we
      // can still trigger events
      $file.css({
        position: "absolute",
        left: "-9999px",
      })

      $wrap.insertAfter($file).append($button)

      $button.after($selected)

      // Prevent focus
      $file.attr("tabIndex", -1)
      $button.attr("tabIndex", -1)

      $button.click(function () {
        $file.focus().click() // Open dialog
      })

      if ($file.val()) {
        this.showSelectedFiles($file, $selected)
      }

      $file.on("input change", () => {
        this.showSelectedFiles($file, $selected)
      })
    })
  }

  showSelectedFiles(input, display) {
    display.html("")

    const files = input[0].files
    Array.from(files).forEach((file) => {
      display.append(`<div class="selected-file">${file.name}</div>`)
    })
  }
}
