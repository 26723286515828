export default class Tabs {
  constructor() {
    this.initSliders();

    $(window).on('resize', () => {
      this.initSliders();
    });

    $('.tabs__heading, .tabs__accordion').on('click', (e) => {
      const target = $(e.currentTarget || e.target);
      this.toggleTab(target);
    });
  }

  toggleTab(target) {
    const parent = target.parents('.tabs');
    const content = parent.find('.tabs__content'),
      tabAccordions = parent.find('.tabs__accordion'),
      tabHeadings = parent.find('.tabs__heading');

    let tabIndex = 0;

    tabHeadings.removeClass('tabs__heading--active');

    if (target.hasClass('tabs__accordion')) {

      tabIndex = target.index('.tabs__accordion');
      tabHeadings.eq(tabIndex).addClass('tabs__heading--active');

      if (target.hasClass('tabs__accordion--active')) {
        target.removeClass('tabs__accordion--active');
        content.stop().slideUp(400);
      } else {
        tabAccordions.removeClass('tabs__accordion--active');
        target.addClass('tabs__accordion--active');

        parent.find('.tabs__content--active').stop().slideUp(400, () => {
          content.removeClass('tabs__content--active');
        });

        const selectedContent = content.eq(tabIndex);
        selectedContent.stop().slideDown(400, () => {
          selectedContent.addClass('tabs__content--active');
        });
      }
    } else {
      target.addClass('tabs__heading--active');
      tabIndex = target.index();

      tabAccordions.removeClass('tabs__accordion--active');
      content.removeClass('tabs__content--active');

      const selectedContent = content.eq(tabIndex);
      selectedContent.prev('.tabs__accordion').addClass('tabs__accordion--active');
      selectedContent.addClass('tabs__content--active');

      this.initSliders();
    }
  }

  initSliders() {
    if ($(window).width() < 700) {
      $('.recent-posts--news .posts:visible:not(".slick-initialized")').slick({
        mobileFirst: true,
        infinite: false,
        responsive: [
          {
            breakpoint: 700,
            settings: 'unslick'
          }
        ]
      });

      $('.recent-posts--events:not(".no-slider") .events:visible:not(".slick-initialized")').slick({
        mobileFirst: true,
        infinite: false,
        responsive: [
          {
            breakpoint: 700,
            settings: 'unslick'
          }
        ]
      });
    }
  }
}