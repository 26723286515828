export default class RegionSelector {
  constructor() {
    this.mapClass = ""
    $(".button-container .button").hover(
      function (e) {
        this.mapClass = e.currentTarget.parentNode.id
        $(".region-map").addClass(this.mapClass)
      },
      function () {
        $(".region-map").removeClass(this.mapClass)
        this.mapClass = ""
      }
    )
  }
}
