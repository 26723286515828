import "jquery-hoverintent"

export default class EquipmentMenu {
  constructor() {
    this.mainNav = $(".primary-nav")
    this.mainNavLinks = this.mainNav.find("> li > a")
    this.subNav = $(".sub-nav")

    this.toggle = $(".equipment-toggle")

    this.equipmentMenu = $(".equipment-menu")
    this.equipmentMenuContainer = $(".equipment-menu-container")
    this.equipmentMenuCategories = $(".equipment-menu__category-container")

    this.menuHeight = 0
    this.categoryWidth = 0

    $(window).on("load resize", () => {
      this.getEquipmentMenuDimensions()
    })

    this.mainNavLinks.on("focus", () => {
      this.hideEquipmentMenu()
    })

    this.subNav.find("> li > a").on("focus", (e) => {
      const target = $(e.currentTarget || e.target)

      if (!target.parent().hasClass("equipment-toggle")) {
        this.hideEquipmentMenu()
        this.hideEquipmentCategories()
      }
    })

    this.mainNavLinks.hoverIntent({
      over: () => {
        this.hideEquipmentMenu()
        this.hideEquipmentCategories()
      },
      sensitivity: 5,
      interval: 50,
    })

    this.subNav.find("> li > a").hoverIntent({
      over: (e) => {
        if (
          !$(e.currentTarget || e.target)
            .parent()
            .hasClass("equipment-toggle")
        ) {
          this.hideEquipmentMenu()
          this.hideEquipmentCategories()
        }
      },
      sensitivity: 5,
      interval: 50,
    })

    const outsideOfNav = $(".header__logo, .header__search, main")
    outsideOfNav.hoverIntent({
      over: () => {
        if (this.equipmentMenu.hasClass("equipment-menu--show")) {
          this.hideEquipmentMenu()
          this.hideEquipmentCategories()
        }
      },
      sensitivity: 5,
      interval: 50,
    })

    this.toggle.find("> a").on("focus", () => {
      this.showEquipmentMenu()
    })

    this.equipmentMenu.find("a").on("focus", () => {
      this.showEquipmentMenu()
    })

    this.toggle.hoverIntent({
      over: () => {
        this.showEquipmentMenu()
        this.removeParentHighlight()
      },
      sensitivity: 5,
      interval: 50,
    })

    this.equipmentMenu.find("> li > a").on("focus", (e) => {
      const element = e.currentTarget || e.target

      this.showEquipmentCategory(element)
    })

    this.equipmentMenu.find("> li > a").hoverIntent({
      over: (e) => {
        this.showEquipmentCategory(e.currentTarget)
        this.removeParentHighlight()
      },
      sensitivity: 5,
      interval: 50,
    })

    this.equipmentMenuCategories.find("a").on("focus", (e) => {
      const target = $(e.currentTarget || e.target)
      this.highlightParent(target, true)
    })

    this.equipmentMenuCategories.on("mouseenter", (e) => {
      const target = $(e.currentTarget || e.target)
      this.highlightParent(target)
    })
  }

  getEquipmentMenuDimensions() {
    // TODO: Remove this since it was breaking JS
    if (!this.toggle.position()) return

    this.equipmentMenu.removeAttr("style")
    this.equipmentMenuCategories
      .removeAttr("style")
      .addClass("equipment-menu__category-container--init")

    // const subNavLeft = parseInt($(".sub-nav").css("left"), 10)
    // const toggleLeft = this.toggle.position().left
    // const equipmentMenuRight =
    // subNavLeft + toggleLeft + this.toggle.width() / 2 + 128

    // this.categoryWidth = $(window).width() - equipmentMenuRight - 24

    this.equipmentMenuContainer.css({ width: 400 })

    this.equipmentMenuCategories.css({
      width: 200,
    })

    const heights = []

    heights.push($(".equipment-menu").outerHeight())

    this.equipmentMenuCategories.each((index, element) => {
      heights.push($(element).outerHeight())
    })

    this.menuHeight = Math.max.apply(null, heights)

    this.equipmentMenuCategories.css({
      width: 0,
      height: this.menuHeight,
    })

    this.equipmentMenuCategories.removeClass(
      "equipment-menu__category-container--init"
    )
  }

  showEquipmentMenu() {
    this.equipmentMenu
      .css({ height: this.menuHeight })
      .addClass("equipment-menu--show")

    this.equipmentMenuContainer.addClass("equipment-menu-container--show")
  }

  hideEquipmentMenu() {
    this.equipmentMenu.css({ height: 0 }).removeClass("equipment-menu--show")

    this.equipmentMenuContainer.removeClass("equipment-menu-container--show")

    this.equipmentMenuCategories
      .css({
        width: 0,
      })
      .removeClass("equipment-menu__category-container--active")

    this.removeFocus()

    this.removeParentHighlight()
  }

  showEquipmentCategory(element) {
    this.equipmentMenuCategories
      .css({
        width: 0,
      })
      .removeClass("equipment-menu__category-container--active")

    this.removeFocus()

    const productLists = $(element).siblings(
      ".equipment-menu__category-container"
    )
    productLists
      .css({
        width: 200,
      })
      .addClass("equipment-menu__category-container--active")

    this.addFocus(productLists)

    this.removeParentHighlight()
  }

  hideEquipmentCategories() {
    this.equipmentMenuCategories
      .css({
        width: 0,
      })
      .removeClass("equipment-menu__category-container--active")

    this.removeFocus()

    this.removeParentHighlight()
  }

  highlightParent(target, link) {
    let parentLink = ""

    if (link) {
      parentLink = target
        .parents(".equipment-menu__category-container")
        .siblings("a")
    } else {
      parentLink = target.siblings("a")
    }

    parentLink.addClass("active")
  }

  removeParentHighlight() {
    this.equipmentMenu.find("> li > a").removeClass("active")
  }

  addFocus(products) {
    if ($(window).width() >= 1024) {
      products.find("a").removeAttr("tabindex")
    }
  }

  removeFocus() {
    if ($(window).width() >= 1024) {
      this.equipmentMenuCategories.find("a").attr("tabindex", -1)
    }
  }
}
